<template>  
    <!-- Mobile Header -->
    <header>
      <button v-on:click="showMobileMenu = !showMobileMenu" v-bind:class="{ open: showMobileMenu }" class="menu"><span></span><span></span><span></span></button>
      <ul class="header-title">
          <li v-show="step == 1 || step == 2">BUSSGELDKANZLEI.DE</li>
          <li v-show="step == 3">2/9 Rechtsschutzversicherung</li>
          <li v-show="step == 4">3/9 Post von Behörde</li>
          <li v-show="step == 5 || step == 6 || step == 7 || step == 8 || step == 9 || step == 10 || step == 11">4/9 Details</li>
          <li v-show="step == 12">5/9 PUNKTE IN FLENSBURG</li>
          <li v-show="step == 13">6/9 Dokumente</li>
          <li v-show="step == 14">7/9 Bereits geblitzt</li>
          <li v-show="step == 15">8/9 Führerschein</li>
          <li v-show="step == 16">9/9 Daten</li>
          <li v-show="step >= 17">{{user.casenumber}} - {{user.firstname}} {{user.name}}</li>
      </ul><a class="whatsapp-header" @click="showHotlineModal(!modalHotline)" href="#mobile-phone">
          <svg>
          <use xlink:href="@/assets/images/sprite.svg#icons8-ringer-volume"></use>
          </svg></a>
      <!-- Mobile menu-->
      <ul v-bind:class="{ open: showMobileMenu }" class="mobile-nav">
          <li><router-link to="/">
              <svg>
              <use xlink:href="@/assets/images/sprite.svg#icons8-external-link"></use>
              </svg>STARTSEITE</router-link></li>
          <li><router-link to="/impressum">
              <svg>
              <use xlink:href="@/assets/images/sprite.svg#icons8-external-link"></use>
              </svg>IMPRESSUM</router-link></li>
          <li><router-link to="/datenschutz">
              <svg>
              <use xlink:href="@/assets/images/sprite.svg#icons8-external-link"></use>
              </svg>DATENSCHUTZ</router-link></li>
      </ul>
    </header>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'HeaderMobile',
  data() {
    return {
        showMobileMenu: false
    };
  },
  methods: {
    ...mapMutations({
        showHotlineModal: 'setModalHotline',
      }),
  },
  computed: mapState({
    step: (state) => state.step,
    user: (state) => state.user,
    modalHotline: (state) => state.modalHotline
  }),
}
</script>